import React from 'react';
import { notifyManager } from '../core/notifyManager';
import { QueriesObserver } from '../core/queriesObserver';
import { useQueryClient } from './QueryClientProvider';
export function useQueries(queries) {
  var mountedRef = React.useRef(false);

  var _React$useState = React.useState(0),
      forceUpdate = _React$useState[1];

  var queryClient = useQueryClient();
  var defaultedQueries = queries.map(function (options) {
    var defaultedOptions = queryClient.defaultQueryObserverOptions(options); // Make sure the results are already in fetching state before subscribing or updating options

    defaultedOptions.optimisticResults = true;
    return defaultedOptions;
  });
  var obsRef = React.useRef();

  if (!obsRef.current) {
    obsRef.current = new QueriesObserver(queryClient, defaultedQueries);
  }

  var result = obsRef.current.getOptimisticResult(defaultedQueries);
  React.useEffect(function () {
    mountedRef.current = true;
    var unsubscribe = obsRef.current.subscribe(notifyManager.batchCalls(function () {
      if (mountedRef.current) {
        forceUpdate(function (x) {
          return x + 1;
        });
      }
    }));
    return function () {
      mountedRef.current = false;
      unsubscribe();
    };
  }, []);
  React.useEffect(function () {
    // Do not notify on updates because of changes in the options because
    // these changes should already be reflected in the optimistic result.
    obsRef.current.setQueries(defaultedQueries, {
      listeners: false
    });
  }, [defaultedQueries]);
  return result;
}